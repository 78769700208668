<template>
  <div>
    <v-card-text>
      <div v-if="items.length == 0">
        <v-progress-linear
          color="primary"
          indeterminate
          height="6"
          v-show="progress"
        ></v-progress-linear>
        <v-alert text type="info">No hay data para mostrar</v-alert>
      </div>
      <div v-else @click.right.prevent @copy.prevent @paste.prevent>
        <v-card outlined class="white pa-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">PRODUCTO</th>
                  <th class="text-left">TIPO PRODUCTO</th>
                  <th class="text-left">CODIGO PRODUCTO</th>
                  <th class="text-left">CONCEPTO</th>
                  <th class="text-left">COMISION</th>
                  <th class="text-left">COMISION PC</th>
                  <th class="text-left">GAMA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.NombreProducto }}</td>
                  <td>{{ item.TipoProducto }}</td>
                  <td>{{ item.ProductCode }}</td>
                  <td>{{ item.Concepto }}</td>
                  <td>{{ item.Comision | currency }}</td>
                  <td>{{ item.ComisionPC | currency }}</td>
                  <td>{{ item.Gama }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
      <br />
      <v-alert type="warning" text class="">
        Los datos aquí presentados son una guía para la interpretación del pago
        de comisiones. Por sí mismo no representan un acuerdo definitivo entre
        las Partes. LA COMPAÑÍA podrá modificar las reglas y montos de
        comisiones de conformidad a los términos y condiciones del Contrato, sin
        embargo, la actualización del Instructivo pudiera no realizarse con la
        misma frecuencia con que se implementan dichas modificaciones.
      </v-alert>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form" lazy-validation>
          <v-autocomplete
            :items="tipoProducto"
            :filter="customFilter"
            v-model="tipoProductoSelected"
            item-text="ProductoName"
            item-value="ProductoTipoId"
            placeholder="Tipos de productos"
            required
            append-icon="keyboard_arrow_down"
            :rules="[(v) => !!v || 'Requerido']"
          ></v-autocomplete>
          <v-autocomplete
            :items="listProductoCodes"
            :filter="customFilter"
            v-model="productoCodesSelected"
            item-text="ProductName"
            item-value="ProductCodeID"
            placeholder="Price plan"
            required
            multiple
            append-icon="keyboard_arrow_down"
            :rules="[(v) => !!v || 'Seleccionar Price Plan']"
          ></v-autocomplete>
          <!-- </div>
            </v-col>
          </v-row> -->
        </v-form>
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            class="ma-1"
            depressed
            color="primary"
            @click="getTarifarios"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
          <v-btn
              block
              depressed
              :loading="loadingDownload"
              @click="download"
            >
              Exportar
            </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiEsquema from "@/api/esquemas";
import apiProducto from "@/api/productos";
import download from "downloadjs";
// import { global } from "@/api/global";

export default {
  data: () => ({
    drawerRight: true,
    items: [],
    progress: false,
    loadingDownload: false,
    tipoProductoSelected: "",
    listProductoCodes: [],
    productoCodesSelected: "",
    customFilter(item, queryText) {
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(item.ProductoName) || hasValue(item.ProductCode);
      const query = hasValue(queryText);
      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  }),
  computed: {
    tipoProducto() {
      return this.$store.getters.tipoProducto;
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    download() {

      if (!this.$refs.form.validate()){
        return;
      }

      let param = {
          canalAsociado: this.currentUser.asociado.canalAsociadoId,
          codigosProductosList: this.productoCodesSelected,
          tipoProducto: this.tipoProductoSelected,
        };


      this.loadingDownload = true;
      apiEsquema
        .getReporteTarifarioExport(param)
        .then((response) => {
          download(response.data, "ComisionesPorProducto" + ".xlsx");
          this.loadingDownload = false;
        })
        .catch((error) => {
          this.setError(error);
          this.loadingDownload = false;
        });
    },
    getTarifarios() {
      if (this.$refs.form.validate()) {
        this.progress = true;

        let param = {
          canalAsociado: this.currentUser.asociado.canalAsociadoId,
          codigosProductosList: this.productoCodesSelected,
          tipoProducto: this.tipoProductoSelected,
        };

        apiEsquema
          .getReporteTarifario(param)
          .then((response) => {
            this.items = response.data;
            this.progress = false;
          })
          .catch((error) => {
            this.$store.dispatch("setError", true);
            this.$store.dispatch("setErrorMessage", error.message);
          });
      }
    },
    refreshProductCodes() {
      apiProducto
        .GetProductCodesByTipoID(this.tipoProductoSelected)
        .then((response) => {
          this.listProductoCodes = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
  },
  watch: {
    tipoProductoSelected: function () {
      this.refreshProductCodes();
    },
  },
  mounted() {},
};
</script>